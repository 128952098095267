$primary: #75bda5;
$secondary: #e54245;
$wht: #fff;
$blk: #333;


.heroSection {
	background: url(/images/heroWht.jpg);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;

	padding: 11% 0%;

	@media (max-width: 767px) {
		padding: 1em 0em 3em;
	}

	h1 	{
		line-height: 85px;
		@media (max-width: 767px) {
			line-height: 50px;			
		}
	}
}





h1,h2
{
	font-family: 'Covered By Your Grace', serif;
	line-height: 55px;
}

h1 {
	font-size: 69px;

	@media (max-width: 767px) {
		font-size: 40px;			
	}
}

h2
{
	font-size: 50px;
	color: $secondary;

	@media (max-width: 767px) {
		font-size: 40px;	
		line-height: 40px;					
	}	
}

h3 {
	font-size: 32px;

	@media (max-width: 767px) {
		font-size: 20px;
	}
}


h4
{
	line-height: 25px;

}


body 
{
	p
	{
		font-size: 19px;
		line-height: 30px;
	}
}


.whtHr
{
	border-color: $wht;
}

hr
{
	width: 50%;
	border-color: $primary;
}


.bg-primary
{
	background-color: $primary;
}

.primaryTxt,
a
{
	color: $primary ;
}

.secondaryTxt
{
	/*color: #774900;*/
	color: $secondary;
}

.hrPrimary
{
	border-color: $primary -moz-use-text-color -moz-use-text-color
}

cite
{
	float: right;
	color: $secondary;
}

blockquote
{
	border-color: $secondary;
	line-height: 30px;
}

.mountBg cite
{
	color: #fff;
}

.btn
{
	font-size: 17px;
		font-weight: bold;
}

.btn-default
{
	background: $secondary;
	color: #fafafa;
	padding: 2% 5%;
	border: none;




	&:hover
	{
		background: #D5001E;
		color: #fff;
		border: none;

	}

}




.btn-default2
{
	background: #eaeaea;
	color: $primary;
	border: none;




	&:hover
	{
		background: #D5001E;
		color: #fff;
		border: none;

	}
}



.btn-default3
{
	background: fade-out($wht, 0.75);
	border: 2px solid $primary !important;
	color: $primary;
	border: none;
	
	font-size: 20px;



	&:hover
	{
		background: #D5001E;
		color: #fff;
		border: none;

	}
}




.btn-xl
{
	padding: 2% 5%;
}



.whtTxt,
a.whtTxt
{

	color: #fff;
}

.blkTxt
{
	color: #333;
}


footer a 
{
	color: #fafafa;
}



/** NAVBAR **/

.navbar
{
	border-bottom: 2px solid $primary;
}

.navbar-default
{
	background-color: #fafafa;
	padding: 0 5%;
	z-index: 1;
	min-height: auto;
}

#navbarContainer
{
	padding: 0;
}



.navbar-default .navbar-nav > li > a:hover, .navbar-default .navbar-nav > li > a:focus
{
	background: $primary;
	color: $wht;
}

.capTxt
{
	text-transform: capitalize;
}

.bold
{
	font-weight: bold;
}



/** END NAVBAR **/


/** LOGIN FORM **/
.modal-dialog
{
	max-width: 250px;
	text-align: center;
	margin: 5em auto;
}

.modal-header, .modal-footer
{
	background: $primary;
	color: #fff;
	text-align: center;
}

input#username
{
	margin-bottom: 20px;
}


/** END LOGIN FORM **/


.overlay
{
	background: rgba(0,189,214,0.7);
	padding: 5% 10%;
}

.overlaySolid
{
	background: fade-out($primary, 0.25);
	padding: 5% 10%;	
}

.borderRadius
{
	border: 1px solid #fff;
	border-radius: 5px;
}

.overlay2
{
	background: rgba(250,250,250,0.65);
	padding: 5% 10%;
}




.navbar-nav {
	margin: 0px;
}

.navbar-default {
	border: none;
	border-radius: 0px;
}

.navbar-default .navbar-nav > li > a:hover {
	background: $primary;
}

.navbar-default .navbar-nav > li > a:focus
{
	background: transparent;
	color: $blk;
	outline: 0 !important;
}

.logo {
	max-width: 60%;
	margin-top: 1em;
}


@media (max-width: 991px) {
  .navbar-header {
    width: 30%;
  }

  .logo {
    max-width: 100%;
  }
}


@media (max-width: 767px) {
	.nav .nav-divider {
		margin: 0;
	}

  .navbar-header {
    width: 100%;
  }
  
  .navbar-toggle {
  	margin-top: 1em;
  	margin-bottom: 1em;
  }

  .navbar .navbar-nav > li > a {
    height: 40px;
    display: block;
	text-align: center;
  }

  .logo {
    max-width: 40%;
    margin-left: 1em;
	margin-top: 0.5em;
  }
}

@media (max-width: 420px) {
	.logo {
		max-width: 60%;
		margin-top: 1em;
	}
}